export const Title = () => {
    return (
        <div className='mb-10 flex flex-col gap-4 z-[0]'>
            <div className='text-center mx-auto montaga-regular text-alpha text-lg'>
                Please join us to celebrate our marriage
            </div>
            <h1 className='montaga-regular text-center font-bold tracking-wider  '>Daria & Andrei</h1>
            <div className='text-center mx-auto montaga-regular text-alpha text-lg'>
                may 2, 2025 - Chisinau, Miadora
            </div>
        </div>
    )
}
